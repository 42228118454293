import { createPortal } from "react-dom";
import Popup from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    zIndex: 1000,
  },
};

const otherStyles = {
  header: {
    fontSize: "1.25rem",
    color: "red",
  },
  text: {
    fontSize: "1rem",
    padding: "16px 0px",
  },
  button: {
    padding: "12px 16px",
    borderRadius: "6px",
    backgroundColor: "#c0c0c0",
    color: "white",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Popup.setAppElement("#portal");

interface Props {
  isOpen: boolean;
  toggle: () => void;
  isEnabled: boolean;
}

export const Modal = ({ isOpen, toggle, isEnabled }: Props) => {
  if (isEnabled) {
    const modal = (
      <div>
        <Popup
          isOpen={isOpen}
          onRequestClose={toggle}
          style={customStyles}
          contentLabel='Example Modal'
        >
          <h2 style={otherStyles.header}>DISCLAIMER:</h2>

          <p style={otherStyles.text}>
            We are experiencing technical difficulties with our inmate images.
            We are aware of the issue and are working with our provider to
            resolve the issue.
          </p>
          <div style={{ display: "flex", justifyContent: "end", gap: "12px" }}>
            <button style={otherStyles.button} onClick={toggle}>
              Dismiss
            </button>
          </div>
        </Popup>
      </div>
    );

    const portal = document.getElementById("portal");

    return isOpen && portal ? createPortal(modal, portal) : null;
  }
  return null;
};
